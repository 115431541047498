import { createContext, ReactNode, useContext, useEffect, useState, useCallback, useRef } from 'react'
import { abilityCheck } from '@/utils/abilityCheck.ts'
import {
    AuthContextType,
    AuthStateType,
    UserDataType,
    AuthLoginRequestType,
    AuthLoginResponseType,
    LastUserDataType,
    TokenDataType,
    TokenExpiryDataType
} from '@/hooks/ClientAuth.type.ts'
import { clientAuthTokenStore } from '@/hooks/ClientAuthTokenStore'
import { abilityCheckFcn, UserAbilitiesActionType, UserAbilitiesPath } from '@/utils/abilityCheck.type.ts'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { envConfig } from '@/env/EnvConfig.ts'
import { toast } from 'react-toastify'
import { createLoginToast } from '@/pages/auth/toasts/LoginToast.tsx'
import { ApiResponseError } from '@/api/requests.type.ts'

import { refreshAuthToken, apiRequest } from '@/api/requests.ts'

// Constants
const API_URL_AUTH_LOGIN = '/auth/login'
const API_URL_AUTH_LOGOUT = '/auth/logout'

const LOCAL_STORAGE_AUTH_KEYS = {
    USER: 'fe.react.user',
    TOKENS: 'fe.react.tokens',
    TOKENS_EXPIRY: 'fe.react.tokens-expiry',
    LAST_USER: 'fe.react.user-last'
}

// ** Defaults
const defaultAuthContext: AuthContextType = {
    user: null,
    lastUser: null,
    state: null,
    resetState: null,
    authenticate: null,
    updateTokens: null,
    ability: null
}

const defaultAuthState: AuthStateType = {
    context: 'unrestricted',
    transaction: null,
    pending: false
}

type AuthContextProviderProps = {
    children: ReactNode
}

const AuthContext = createContext<AuthContextType>(defaultAuthContext)

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
    const [user, setUser] = useState<UserDataType | null>(defaultAuthContext.user)
    const [lastUser, setLastUser] = useState<LastUserDataType | null>(null)
    const [state, setState] = useState<AuthStateType | null>(defaultAuthState)
    const isInitialized = useRef(false)
    const queryClient = useQueryClient()

    const handleLoginSuccess = useCallback(
        (response: AuthLoginResponseType) => {
            const { me, tokens, tokens_expiry } = response.data
            setUser(me)
            clientAuthTokenStore.setTokens(tokens)
            queryClient.clear()
            localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.USER, JSON.stringify(me))
            localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.TOKENS, JSON.stringify(tokens))
            localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.TOKENS_EXPIRY, JSON.stringify(tokens_expiry))

            localStorage.setItem(
                LOCAL_STORAGE_AUTH_KEYS.LAST_USER,
                JSON.stringify({
                    login: me.login,
                    name: me.name
                })
            )
            setState({
                context: 'restricted',
                pending: false,
                transaction: {
                    endpoint: API_URL_AUTH_LOGIN,
                    success: true,
                    error: null
                }
            })
            createLoginToast()
        },
        [queryClient]
    )
    const handleLoginError = useCallback((error: ApiResponseError) => {
        setState({
            context: 'unrestricted',
            pending: false,
            transaction: {
                endpoint: API_URL_AUTH_LOGIN,
                success: null,
                error: error
            }
        })
    }, [])

    const loginMutation = useMutation<AuthLoginResponseType, ApiResponseError, AuthLoginRequestType>({
        mutationFn: async props =>
            apiRequest(envConfig.config.apiUrl + API_URL_AUTH_LOGIN, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ login: props.login, password: props.password })
            }),
        onSuccess: handleLoginSuccess,
        onError: handleLoginError
    })
    const handleLogoutSuccess = useCallback(() => {
        setState({
            context: 'logout',
            pending: false,
            transaction: {
                endpoint: API_URL_AUTH_LOGOUT,
                success: true,
                error: null
            }
        })
    }, [])
    const handleLogoutError = useCallback((error: ApiResponseError) => {
        setState({
            context: 'logout',
            pending: false,
            transaction: {
                endpoint: API_URL_AUTH_LOGOUT,
                success: null,
                error: error
            }
        })
    }, [])

    const logoutMutation = useMutation({
        mutationFn: (id: string) =>
            apiRequest(envConfig.config.apiUrl + API_URL_AUTH_LOGOUT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: id })
            }),
        onSuccess: handleLogoutSuccess,
        onError: handleLogoutError
    })
    const resetAuthState = useCallback(() => {
        const prevStateContext = state?.context || 'unrestricted'
        setState({
            context:
                prevStateContext == 'logout' ||
                prevStateContext == 'logout_timeout' ||
                prevStateContext == 'logout_error'
                    ? 'unrestricted'
                    : prevStateContext,
            pending: false,
            transaction: null
        })
    }, [state?.context])

    const updateTokens = useCallback((tokens: TokenDataType, tokens_expiry: TokenExpiryDataType) => {
        clientAuthTokenStore.setTokens(tokens)
        localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.TOKENS, JSON.stringify(tokens))
        localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.TOKENS_EXPIRY, JSON.stringify(tokens_expiry))
    }, [])
    const authenticate = useCallback(
        (
            userCredentials?: AuthLoginRequestType | null,
            reason?: 'user' | 'expired' | 'auth_error',
            userUd?: string
        ) => {
            toast.dismiss()
            console.log('(authenticate)', 'reason:', reason)
            if (userCredentials) {
                console.log('(login)', 'make validation_call to api,', userCredentials.login)
                setState({
                    context: 'restricted',
                    pending: true,
                    transaction: {
                        endpoint: API_URL_AUTH_LOGIN,
                        success: true,
                        error: null
                    }
                })
                localStorage.setItem(
                    LOCAL_STORAGE_AUTH_KEYS.LAST_USER,
                    JSON.stringify({
                        login: userCredentials.login,
                        name: userCredentials.login
                    })
                )
                loginMutation.mutate(userCredentials)
            } else {
                const currentUserId = user?.id ?? userUd
                console.log('(logout)', 'user_id: ', currentUserId, 'reason:', reason)
                localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.USER)
                localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.TOKENS)
                localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.TOKENS_EXPIRY)
                setUser(null)
                clientAuthTokenStore.clearTokens()
                toast.dismiss()
                if (currentUserId) {
                    setState({
                        context: reason == 'user' ? 'logout' : reason == 'expired' ? 'logout_timeout' : 'logout_error',
                        pending: true,
                        transaction: {
                            endpoint: API_URL_AUTH_LOGOUT,
                            success: null,
                            error: null
                        }
                    })
                    console.log(
                        '(logout)',
                        'make validation_call to api: ',
                        currentUserId,
                        'reason:',
                        reason == 'user' ? '1' : reason == 'expired' ? '2' : '3',
                        reason
                    )

                    if (currentUserId.length > 2) {
                        logoutMutation.mutate(currentUserId)
                    }

                    window.history.replaceState(
                        null,
                        '',
                        '?logout=' + (reason == 'user' ? '1' : reason == 'expired' ? '2' : '3')
                    )
                }
            }
        },
        [user, loginMutation, logoutMutation]
    )
    const handleLocalStorageChange = useCallback((event: StorageEvent) => {
        if (typeof window === 'undefined') return // Ensure this runs only on the client side

        if (event.key === LOCAL_STORAGE_AUTH_KEYS.TOKENS) {
            const storedToken: string | null = window.localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.TOKENS)
            if (storedToken) {
                try {
                    const parsedToken = JSON.parse(storedToken)
                    clientAuthTokenStore.setTokens(parsedToken)
                    const storedUser: string | null = window.localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.USER)
                    if (typeof storedUser === 'string') {
                        const storedUserObj = JSON.parse(storedUser)
                        setUser({ ...storedUserObj })
                    }
                } catch (error) {
                    console.error('Error parsing stored token', error)
                    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.USER)
                    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.TOKENS)
                    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.TOKENS_EXPIRY)
                    setUser(null)
                }
            } else {
                localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.USER)
                localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.TOKENS)
                localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.TOKENS_EXPIRY)
                setUser(null)
            }
        }
    }, [])
    useEffect(() => {
        window.addEventListener('storage', handleLocalStorageChange)
        return () => {
            window.removeEventListener('storage', handleLocalStorageChange)
        }
    }, [handleLocalStorageChange])
    useEffect(() => {
        const initAuth = async function () {
            isInitialized.current = true
            const storedUser: string | null = window.localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.USER)
            const storedToken: string | null = window.localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.TOKENS)
            let sourceUser = 'n/a'
            if (storedUser && storedToken) {
                const storedUserObj = JSON.parse(storedUser)
                setUser({ ...storedUserObj })
                clientAuthTokenStore.setTokens(JSON.parse(storedToken))
                sourceUser = 'from local storage'
                const response = await refreshAuthToken()
                if (response[0] === 'success') {
                    updateTokens(
                        response[1].data.tokens as TokenDataType,
                        response[1].data.tokens_expiry as TokenExpiryDataType
                    )
                } else if (response[0] === 'expired') {
                    const userId = storedUserObj.id
                    console.log('(initAuth)', 'refresh token response: expired')
                    authenticate(null, 'expired', userId)
                } else {
                    const userId = storedUserObj.id
                    console.log('(initAuth)', 'refresh token response: auth_error')
                    authenticate(null, 'auth_error', userId)
                }
            } else {
                setUser(null)
                clientAuthTokenStore.clearTokens()
            }

            const storedLastUser: string | null = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.LAST_USER)
            if (storedLastUser) {
                setLastUser({ ...JSON.parse(storedLastUser) })
            } else {
                setLastUser(null)
            }
            console.log(
                '(initAuth)',
                'storedUser:',
                sourceUser,
                'storedLastUser:',
                storedLastUser ? 'from local storage' : 'n/a'
            )
        }
        if (!isInitialized.current) {
            initAuth().catch(error => {
                console.error('Initialization error: ', error)
            })
        }
    }, [updateTokens, authenticate])

    const checkAbility: abilityCheckFcn = (
        abilityPath: UserAbilitiesPath = null,
        abilityAction: UserAbilitiesActionType = '*'
    ): boolean => {
        return abilityCheck(user?.abilities, abilityPath, abilityAction)
    }
    const values = {
        user,
        lastUser,
        state,
        resetState: resetAuthState,
        authenticate: authenticate,
        updateTokens: updateTokens,
        ability: checkAbility
    }
    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

const useClientAuth = () => useContext(AuthContext)

export { AuthContext, AuthContextProvider, useClientAuth }
