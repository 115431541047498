import './FullscrenLoader.css'

export const FullscreenLoader = () => {
    return (
        <div className='loader-wrapper'>
            <div className='loader hide-no-js'></div>
            <div className='loader-text'></div>
        </div>
    )
}
