import { RouteObject } from 'react-router-dom'
import { FullscreenLoader } from '@/layout/components/elements/FullscreenLoader.tsx'

//import { Home } from './pages/Home'
//import { Page1 } from './pages/Page1'
//import { Page2 } from './pages/Page2withDelay'
//import { Page3 } from './pages/Page3withAnimation'

export const routes: RouteObject[] = [
    {
        hydrateFallbackElement: <FullscreenLoader />,
        children: [
            {
                index: true,
                id: 'root',
                lazy: () => import('@/pages/Home.tsx'),
                //element: <Home />,
                path: '/'
            },
            {
                lazy: () => import('@/pages/auth/ForgotPassword.tsx'),
                // element: <Page1 />,
                path: '/forgot-password'
            },
            {
                lazy: () => import('@/pages/auth/Register.tsx'),
                // element: <Page1 />,
                path: '/register'
            },
            {
                lazy: () => import('@/pages/legal/TermsOfService.tsx'),
                // element: <Page1 />,
                path: '/legal/tos'
            },
            {
                lazy: () => import('@/pages/legal/DataPrivacy.tsx'),
                // element: <Page1 />,
                path: '/legal/data-privacy'
            },
            {
                lazy: () => import('@/pages/legal/Imprint.tsx'),
                // element: <Page1 />,
                path: '/legal/imprint'
            },
            {
                lazy: () => import('@/pages/support/Contact.tsx'),
                // element: <Page1 />,
                path: '/contact'
            },
            {
                lazy: () => import('@/pages/Home1.tsx'),
                // element: <Page1 />,
                path: '/home1'
            },
            {
                lazy: () => import('@/pages/Home2.tsx'),
                // element: <Page1 />,
                path: '/home2'
            },
            {
                lazy: () => import('@/pages/Map.tsx'),
                // element: <Page1 />,
                path: '/map'
            },
            {
                lazy: () => import('@/pages/Page1.tsx'),
                // element: <Page1 />,
                path: '/page1'
            },
            {
                lazy: () => {
                    return new Promise(resolve => {
                        setTimeout(() => resolve(import('@/pages/Page2withDelay.tsx')), 2000)
                    })
                },
                //element: <Page2 />,
                path: '/page2'
            },
            // ---------------------- DEVELOPMENT MENU
            {
                lazy: () => import('@/pages/dev/Info.tsx'),
                //element: <PageTests />,
                path: '/dev/info'
            },
            // ---------------------- DEVELOPMENT MENU - Theme
            {
                lazy: () => import('@/pages/dev/theme/elements/Typography.tsx'),
                //element: <PageTests />,
                path: '/dev/theme/elements/typography'
            },
            {
                lazy: () => import('@/pages/dev/theme/elements/Boxes.tsx'),
                //element: <PageTests />,
                path: '/dev/theme/elements/boxes'
            },
            {
                lazy: () => import('@/pages/dev/theme/elements/Buttons.tsx'),
                //element: <PageTests />,
                path: '/dev/theme/elements/buttons'
            },
            {
                lazy: () => import('@/pages/dev/theme/elements/ButtonGroups.tsx'),
                //element: <PageTests />,
                path: '/dev/theme/elements/buttongroups'
            },
            {
                lazy: () => import('@/pages/dev/theme/elements/Dropdowns.tsx'),
                //element: <PageTests />,
                path: '/dev/theme/elements/dropdowns'
            },
            {
                lazy: () => import('@/pages/dev/theme/elements/Alerts.tsx'),
                //element: <PageTests />,
                path: '/dev/theme/elements/alerts'
            },
            {
                lazy: () => import('@/pages/dev/theme/elements/Modals.tsx'),
                //element: <PageTests />,
                path: '/dev/theme/elements/modals'
            },
            {
                lazy: () => import('@/pages/dev/theme/elements/Skeletons.tsx'),
                //element: <PageTests />,
                path: '/dev/theme/elements/skeletons'
            },
            {
                lazy: () => import('@/pages/dev/theme/elements/Toasts.tsx'),
                //element: <PageTests />,
                path: '/dev/theme/elements/toasts'
            },
            {
                lazy: () => import('@/pages/dev/theme/elements/Tables.tsx'),
                //element: <PageTests />,
                path: '/dev/theme/elements/tables'
            },
            {
                lazy: () => import('@/pages/dev/theme/elements/Offcanvas.tsx'),
                //element: <PageTests />,
                path: '/dev/theme/elements/offcanvas'
            },
            {
                lazy: () => import('@/pages/dev/theme/elements/Accordions.tsx'),
                //element: <PageTests />,
                path: '/dev/theme/elements/accordions'
            },
            {
                lazy: () => import('@/pages/dev/theme/components/DynTable.tsx'),
                //element: <PageTests />,
                path: '/dev/theme/components/dyntables'
            },

            {
                lazy: () => import('@/pages/dev/theme/animations/Animations.tsx'),
                //element: <Page3 />,
                path: '/dev/theme/animations'
            },
            {
                lazy: () => import('@/pages/dev/theme/layouts/Pure'),
                //element: <PageTests />,
                path: '/dev/theme/layouts/pure'
            },
            // ---------------------- DEVELOPMENT MENU - Examples
            {
                lazy: () => import('@/pages/dev/examples/CreateIssue'),
                //element: <PageTests />,
                path: '/dev/examples/create-issue'
            },
            // ---------------------- DEVELOPMENT MENU - I18N
            {
                lazy: () => import('@/pages/dev/i18n/I18n.tsx'),
                //element: <PageTests />,
                path: '/dev/i18n'
            },
            // ---------------------- DEVELOPMENT MENU - Debug
            {
                lazy: () => import('@/pages/dev/debug/Sentry.tsx'),
                //element: <PageTests />,
                path: '/dev/debug/sentry'
            },
            // ---------------------- DEVELOPMENT MENU - Errors
            {
                //element: <Error404 />,
                lazy: () => import('@/pages/errors/Error500.tsx'),
                path: '/dev/errors/500'
            },
            // ---------------------- CATCHALL (*)
            {
                //element: <Error404 />,
                lazy: () => import('@/pages/errors/Error404.tsx'),
                path: '*'
            }
        ]
    }
]
